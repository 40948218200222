import { Slot } from "@radix-ui/react-slot";
import { type VariantProps, cva } from "class-variance-authority";
import * as React from "react";

import { cn } from "~/lib/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-[6px] font-medium focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 transition-all duration-300 ",
  {
    variants: {
      variant: {
        primary: "bg-black hover:bg-[#737373] text-white",
        secondary:
          "border-[2px] border-gray-deep hover:bg-gray-cold text-black",
      },
    },
    defaultVariants: {
      variant: "primary",
    },
  },
);

const buttonVariants_v2 = cva(
  cn(
    "px-4 py-3 inline-flex items-center justify-center whitespace-nowrap rounded-[6px] font-semibold text-[18px]",
    "focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 transition-all duration-300",
  ),
  {
    variants: {
      variant: {
        primary: "bg-black hover:bg-[#16161566] text-white",
        secondary:
          "outline-[2px] outline-gray-deep outline hover:bg-gray-warm text-[#100F0F]",
      },
    },
    defaultVariants: {
      variant: "primary",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  v2?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, asChild = false, v2 = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(
          v2
            ? buttonVariants_v2({ variant, className })
            : buttonVariants({ variant, className }),
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
